.Bottom{
    width: 100%;
    /* position: relative; */
    /* bottom: 0; */
    height: 100px;
    z-index: 99;
    background-color: rgba(24, 27, 34, 1);
}

.btnlist{
    height: 50px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.btnlist p{
    width: 100px;
    color: #fff;
    font-size: 18px;
    margin: 0 116px;
    cursor: pointer;
}

.btnlist p:hover{

   color: #fff;
    font-size: 24px;
    margin: 0 116px;
}

.directions{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center
}

.directions p{
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    margin: 0 170px;
}