.H5protocolPolicy{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.H5protocolPolicy p {
    margin: 20px;
    color: #fff;
    font-size: 16px;
    white-space:pre-wrap;

}