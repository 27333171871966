.H5home{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 38px;
}

.H5home img{
    width: 300px;
    height: 91px;
}

.H5home button{
    width: 259px;
    height: 28px;
    background-color: rgba(120 ,224 ,219,1) ;
    border: none;
    border-radius: 16px;
    color: rgba(25, 28, 52,1);
    font-size: 12px;
    margin-top: 20px;
}

.bg{
    width: 100% !important;
    height: 100% !important;
    object-fit: fill;
}

.H5home p{
    color: #fff;
    font-size: 30px;
    margin:  0 0 10px 0;
}

.info{
    width: 100%;
   margin: 0 30px;
    position: relative;
    top: -80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.list-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    

}

.list-item img{
    width: 240px;
    height: 240px;
}

.list-item p{
    color: #fff;
    font-size: 20px;
}
.list-item span{
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    text-align: center;
    width: 80%;
}