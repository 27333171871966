
  .swiper-s {
    height: 430px ;
    width: 400px !important;
    text-align: center;
  }


.Member{
    margin-top: 168px;
    display: flex;
    flex-direction: column;
    align-items: center;
   
}


.Member p{
    color: #fff;
    font-size: 40px;
}

.pay{
    width: 100%;
}

.pay-item{
    width: 400px;
    height: 430px;
    background-color: rgba(29, 53, 60, 1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;  
    align-items: center; 
    margin: 0 30px;
    cursor: pointer;
  
}

.pay-item p{
    margin: 0;
    color:rgba(232 ,200 ,142,1);
    font-size:30px;
    margin-top: 40px;
    text-align: center;
    
}

.pay-item p span{
    font-size: 81px;
    color: #fff;
}

.pay-item-active{
    width: 400px;
    height: 430px;
    background-color: rgba(29, 53, 60, 1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;  
    align-items: center; 
    margin: 0 30px;
    border: 2px solid rgba(232 ,200 ,142,1);
    cursor: pointer;
}

.pay-item-active p{
    margin: 0;
    color:rgba(232 ,200 ,142,1);
    font-size:30px;
    margin-top: 40px;
    text-align: center;
}

.pay-item-active p span{
    font-size: 81px;
    color: #fff;
}


.discount{
    width: 192px;
    height: 60px;
    background-image: linear-gradient(to right ,rgba(243, 221, 170,1) , rgba(231, 198, 139,1) );
    border-radius: 37px;
    font-size: 24px;
    color: rgba( 24, 31, 36,1);
    text-align: center;
    line-height: 60px;
}

.recom{
    line-height: 48px;
    width: 100%;
    height: 48px;
  
    display: flex;    
}
.recom-txt{
    color: #fff;
    font-size: 30px;
    padding: 0 14px;
    border-radius: 18px 0 10px 0;
    background-image: linear-gradient(to right,  rgb( 81,52, 240), rgb(255, 76, 136));
}

.pay-method{
    background-color: rgba(19, 25, 34,1) ;
    width: 1316px;
    height: 400px;
    border-radius: 20px;
    margin-top: 64px;
    margin-bottom: 88px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.pay-method p{
    margin: 0 0 75px 0;

}

.pay-content{
    display: flex;
    align-items: center;
   
}

.pay-content img{
    width: 90px;
    height: 90px;
}

.pay-content span{
    color: #fff;
    font-size: 20px;
}

.pay-method-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 100px;
}