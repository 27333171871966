.H5member{
    text-align: center;

}


.H5member p{
    color: #fff;
    font-size: 30px;
    margin: 16px 0;
}


.swiper-slide{

    display: flex !important;
    align-items: center;
    justify-content: center;

}

.swiper-slide-select{
    width: 186px;
    height: 200px;
    background-color: rgba(29, 53, 60,1);
    border: 2px solid rgba(222, 178, 99,1);
    font-size: 30px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.swiper-slide-select p{
    margin: 0;
    color: rgba( 232, 200, 142,1);
    font-size: 14px;
    margin:8px 0;
}

.swiper-slide-normal{
    width: 150px;
    height: 160px;
    background-color: rgba(29, 53, 60,1);
    border: 2px solid rgba(222, 178, 99,1);
    font-size: 30px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.swiper-slide-normal p{
    margin: 0;
    color: rgba( 232, 200, 142,1);
    font-size: 14px;
    margin: 4px 0;
}

.discount-bg{
    width: 90px;
    height: 28px;
    background-image: linear-gradient(to right ,rgba(243, 221, 170,1) , rgba(231, 198, 139,1) );
    border-radius: 18px;
    font-size: 12px;
    color: rgba( 24, 31, 36,1);
    text-align: center;
    line-height: 28px;
    margin-bottom: 8px;
}

.h5recom{
    width: 100%;
    height: 22px;  
    display: flex;    
}

.h5-recom-txt{
    color: #fff;
    font-size: 14px;
    padding: 0 14px;
    border-radius: 8px 0 10px 0;
    background-image: linear-gradient(to right,  rgb( 81,52, 240), rgb(255, 76, 136));
}

.member-content{
    width: 100%;
    height: 429px;
    background-image: url(../assets/bg2.png);
    background-size: 100% 100%;
}

.pay-list{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.pay-list-item img{
    width: 60px;
    height: 60px;
}

.pay-list-item p{
    color: #fff;
    font-size: 14px;
    margin: 10px 0 0 0;
}

