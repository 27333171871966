.Download{
    margin-top: 168px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}   

.Download p{
    color: rgba(255 ,255 ,255, 0.4);
    font-size: 18px;
    white-space:pre-wrap;
}

.top-layout{
    display: flex;
}

.Download-left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.Download-left p:first-child{
    color: #fff;
    font-size: 61px;
    margin: 0;
}


.Download-left span{
    color: rgba(255 ,255 ,255, 0.6);
    font-size: 30px;
    margin-top: 25px;
}

.Download-left button{
    border: none;
    width: 398px;
    height: 74px;
    border-radius: 12px;
    background-color:rgba(37 ,233 ,223,1) ;
    color: #000;
    font-size: 30px;
    margin-top: 49px;
    cursor: pointer;
}

.info-item{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
}

.info-item img{
    width: 40px !important;
    height: 40px !important;
}

.info-item p{
    color: #fff;
    font-size: 18px;
    margin:0;
}

.top-layout img{
    width: 600px;
    height: 600px;
    display: flex;
    align-items: center;
}

.downloadlist{
    display: flex;
    align-items: center;
    margin-top: 55px;
}
.download-item{
    border: rgba(255, 255, 255, 0.2) solid 1px;
    width: 150px;
    height: 165px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0 25px;
    cursor: pointer;
    opacity: 0.5;
}

.download-item:hover{
    opacity: 1;
}

.download-item img{
    width: 90px;
    height: 90px;
    
}

.download-item span{
    color: rgba(255, 255, 255, 0.6);
    font-size: 27px;
}

.viplayout{
    margin-top: 36px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.viplist{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.vip-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    margin-bottom: 64px;
}

.vip-item img{
    width: 180px;
    height: 180px;
}

.vip-item span{
    color: #fff;
    font-size: 30px;
}

.vip-item p{
    color: #fff;
    text-align: center;
    font-size: 30px;
}
