.H5bottom{
    width: 100%;
    /* position: fixed;
    bottom: 0; */
    display: flex;
    flex-direction: column;
    align-items: center;
}


.downlist{
    width: 100%;
    background-color: rgba(32, 39, 50,1);
    height: 221px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: solid rgba(255, 255, 255, 0.1) 1px;
}

.downlist-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33%;
    height: 110px;
}

.downlist-item img{
    width: 40px;
    height: 40px;
}

.downlist-item span{
    color: #fff;
    font-size: 12px;
}

.connect-list{
    height: 180px;
    width: 100%;
    background-color: rgba(32, 39, 50,1);
    display: flex;

    justify-content: center;

}

.connect-list-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
    margin-top: 18px;
}   

.connect-list-item img{
    width: 90px;
    height: 90px;
}

.connect-list-item p{
    color: rgba(255 ,255 ,255, 0.6) ;
    font-size: 12px;
    margin: 0;
}

.bottom{
    width: 90%;
    background-color: rgba(28, 34, 43, 1);

}

.bottom p{
    color: #fff;
    font-size: 12px;
}

.txt-btn{
    height: 32px;
    display: flex;
    align-items: center;
}

.txt-btn p{
    color: #fff;
    font-size: 14px;
    white-space: pre;
}

.noinfo{
    line-height: 32px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    
}