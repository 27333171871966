.H5sidebar{
    text-align: center;
}

.btn-list{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(27, 41, 50,1) ;
    border-radius: 0 0 12px 12px;
}

.btn-list-item{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-list-item img{
    width: 16px;
    height: 16px;
    margin-right: 21px;
}

.btn-list-item-left{
    display: flex;
    align-items: center;
    margin-left: 28px;
}

.btn-list-item-left img{
    width: 30px;
    height: 30px;
   
}

.btn-list-item-left p{
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
}

.language-list{
    width: 100%;
    padding:  10px 0;
    background-color: rgba(24, 27 ,34,1) ;
    border-radius: 12px 12px 0 0;
    position: fixed;
    bottom: 0;
}

.language-list p{
    color:rgba(255, 255, 255, 0.8);
    font-size: 18px;
}

.language-list button{
    background-image: linear-gradient(to right ,rgba(110, 219, 224,1) , rgba(155, 243, 205,1) );
    width: 327px;
    height: 50px;
    border: none;
    color: black;
    border-radius: 12px;
    margin: 44px 0;
    font-size: 16px;

}

.language-list-item{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.language-list-item p{
    color:rgba(255, 255, 255, 0.8);
    font-size: 14px;
    margin-left: 14px;
}

.language-list-item img{
    width: 16px;
    height: 16px;
    margin-right: 14px;
}