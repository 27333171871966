.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.ant-message{
  top: 50% !important;
}


/* 
@media screen and (max-width:500px) {
  .App {
    display: block;
  }
} */