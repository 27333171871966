.H5rebate{
    text-align: center;

}
.H5rebate p{
    color: #fff;
    font-size: 30px;
}

.H5rebate-top{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(24,27, 34, 1);
    padding-bottom: 30px;
}
.H5rebate-top p{
    color: #fff;
    font-size: 30px;
}

.H5rebate-top span{
    color: rgba(255, 255 ,255, 0.6);
    font-size: 16px;
}

.H5rebate-top img{
    width: 300px;
    height: 270px;
}
.H5rebate-top button{
    background-color: rgba(37, 233, 223,1);
    width: 222px;
    height: 40px;
    border: none;
    border-radius: 4px;
    color: #000;
    font-size: 16px;
    /* padding-bottom: 30px; */
}

.u-list{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.u-list-item{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.u-list-item img{
    width: 180px;
    height: 180px;
}

.u-list-item p{
    color: #fff;
    font-size: 20px;
}

.details-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(24,27, 34, 1);
}

.details-list-item p{
    color: #fff;
    font-size: 20px;
}

.details-list-item img{
    width: 100px;
    height: 100px;
}

.details-content{
    white-space:pre-wrap;
    font-size: 14px !important;
}