.Connect{
    width: 65%;
    margin-top: 168px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.Connect p{
    color:#fff;
    font-size: 40px;
}

.ContactList{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.listItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    /* margin: 0 100px; */

}


.listItem img{
    width: 180px;
    height: 180px;
}

.listItem p {
    font-size:30px;
    color: #fff;
    margin: 0;
}

.introduce{
    margin-top: 100px;
    /* padding: 0 400px;  */
}

.introduce p{
   color: #fff;
   font-size: 30px;
}

.introduce span{
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
 }

 .map{
    width: 100%;
    height: 502px;
    margin-top: 50px;
}

.content{
    width: 75%;
}

.content-item{
    width: 100%;
    height: 237px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    

}

.content-item-info{
    width: 426px;
}

.content-item img{
    width:180px;
    height: 180px;
}

.content-item-info p{
    color: #fff;
    font-size: 30px;
    margin-bottom: 10px;
}

.content-item-info span{
    width: 372px;
    color: #fff;
    font-size: 18px;
}
    