.Rebate{
    width: 100%;
    margin-top: 168px;
    display: flex;
    flex-direction: column;
    align-items:  center;
}

.acting{
    width: 100%;
    height: 514px;
    background-color: rgba(28, 34, 43,1);
    display: flex;
    align-items: center;
    justify-content: center;

}

.acting img{
    width: 400px;
    height: 360px;
    margin-left: 195px;
}

.left{
    height: 100%;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
}
.left p{
    color: #fff;
    font-size: 61px;
    margin: 0;
}
.left span {
    font-size: 30px;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 25px;
}

.left button{
    background-color: rgba(37, 233, 223,1);
    width: 400px;
    height: 74px;
    border: none;
    border-radius: 12px;
    font-size: 30px;
    color: rgba(24, 31, 36, 1) ;
    margin-top: 91px;
    cursor: pointer;
}

.rebate{
    background-color: rgba(24, 27, 34,1);
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rebate p{
    color: #fff;
    font-size: 61px;
    margin: 0;
}

.rebate-list{

    display: grid;
    grid-template-columns: 260px 260px 260px;
    grid-template-rows: 200px 100px 100px;
     grid-column-gap: 250px;
     grid-row-gap: 40px;
}

.rebate-list-item{
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rebate-list-item img{
    width: 180px;
    height: 180px;
}
.rebate-list-item span{
    color: #fff;
    font-size: 20px;
}

.details{
    width: 65%;
    height: 700px;
    background-color: rgba(28, 34, 43,1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.details-item{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.details-item-left{
    display: flex;
    flex-direction: column;
}


.details-item-title{
    color: #fff;
    font-size: 30px;
    margin: 0;
}

.details-item-content{
    color: rgba(255, 255, 255, .6);
    font-size: 18px;
    white-space:pre-wrap;
    margin: 34px 0 0 0;
    


}

.details-item img{
    width: 180px;
    height: 180px;
}