.Top {
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    background-color: rgba(24, 27, 34, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
}

.line{
    position: fixed;
    top: 70px;
    width: 100%;
    height: 10px;
    background-color: rgba(24, 27, 34, 1);
}

.top-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 164px;
}

.top-left img{
    width: 164px;
    height: 53px;
}

.language {
    width: 300px;
    height: 100px;
    display: flex;
    align-items: center;
    /* margin-right: 250px; */
    z-index: 99;
    cursor: pointer;
}

.language span {
    color: #fff;
    font-size: 18px;
    margin-left: 8px;

}

.languageBg {
    position: fixed;
    top: 60px;
    width: 202px;
    padding: 10px 0;
    background-color: rgba(24, 31, 36, 1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.language-item{
    width: 100%;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 1px solid rgba(234,234, 234, 0.1);

}

.language-item img{
    width: 32px;
    height: 32px;
}

.language-item p{
    font-size: 14px;
    color: #fff;
    margin: 0px !important;
}


.ant-tabs-nav {
    position: inherit !important;
}

.ant-tabs-tab-btn {
    color: #fff !important;
    font-size: 20px;
}

.ant-tabs-ink-bar {
    height: 5px !important;
    border-radius: 3px;
    background-color: rgba(120, 224, 219, 1) !important;
}
