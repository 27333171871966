.H5dwonload{
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-left: 20px;

}

.H5dwonload p{
    color: #fff;
    font-size: 30px;
}

.H5dwonload span{
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;

}

.H5dwonload img{
    width: 100%;
    height: 100%;


}

.info-list{
    height: 350px;
    display: flex;
    flex-direction: column;
    background-image: url(../assets/bg3.png);
    background-size: 100% 100%;
    margin-top: 96px;

}


.info-list-item{
    display: flex;
    align-items: center;
    margin-top: 4px;

}

.info-list-item img{
    width: 22px;
    height: 22px;
}

.info-list-item p{
    color: #fff;
    font-size: 12px;
    margin: 0;
}

.h5download-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
}

.h5download-content button{
    background-color: rgba(37, 233, 223,1);
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 4px;
    color: #000;
    font-size: 16px;
}

.platform-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 35px;
}

.platform-list-item{
    width: 91px;
    height: 100px;
    border-radius: 8px;
    border: rgba(255, 255 ,255, 0.2) solid 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 7px;
    
    
}
.platform-list-item img{
    width: 55px;
    height: 55px;
    margin-top: 6px;
}
.platform-list-item span{
    font-size: 16px;
    color: #fff;
    opacity: 0.8;
}
.h5vip-list{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.h5vip-list-item{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.h5vip-list-item img{
    width: 180px;
    height: 180px;
}

.h5vip-list-item p{
    color: #fff;
    font-size: 30px;
}


