.InviteBg{

    padding-top: 104px;
    font-size: 0;
    width: 100%;
    height: 800px;
    background-image: url(../assets/bg1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

}

.invite-list{
    display: flex;
    align-items: center;
    justify-content: center;
}

.tipsicon{
    margin-top: 129px;
    margin-left:21px;
    width: 783px;
    height: 233px;
}

.inviteicon{
    width: 579px;
    height: 500px;
    margin-left: 132px;
}

.invitelayout{
    display: flex;
    align-items: center;
    margin-left: 200px;
}

.invite-item{
    width: 300px;
    height: 42px;
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.btn{
    width: 400px;
    height: 74px;
    background-color: rgba(37, 233, 223,1);
    border-radius: 37px;
    border: none;
    font-size: 30px;
    color: rgba(24, 31, 36,1);
    margin-top: 160px;
    margin-left: 200px;
    font-weight: bold;
    cursor: pointer;
}

/* .h5tipsicon{
    display: none;
} */

@media screen and (max-width:500px) {

    /* .InviteBg{
        margin-top: 86px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-image: none;
    }

    .tipsicon{
        display: none;
    }
    .h5tipsicon{
        display: block;
    } */
}