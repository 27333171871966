.Advantagelayout{
    width: 100%;
    /* height: 1080px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-bottom: 88px; */
}

.Advantagelayout p{
    color: #fff;
    font-size: 60px;
    margin: 0;
}

.Advantagelayout span{
    color: rgba(255 ,255 ,255, 0.6);
    font-size: 30px;
    margin-top: 40px;
    white-space:pre-wrap;
}

.container {
    width: 70%;
    display: flex;
    flex-wrap: wrap;

}

.item {
    width: 33%;
    height: 380px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* background-color: black; */
}

.item span{
    margin-top: 20px;
    width: 80%;
    color: rgba(255,255,255,0.6);
    font-size: 16px;
    text-align: center;
}

.device-list{
    display: flex;
    margin-top: 51px;
}

.device-list-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    height: 165px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    margin: 0 47px;
    cursor: pointer;
    opacity: 0.5;
}
.device-list-item:hover{
    opacity: 1;
}

.device-list-item img{
    width: 90px;
    height: 90px;
    margin-top: 12px;
}



.device-list-item span{
    margin: 0;
    font-size: 27px;
    color: rgba(255 ,255 ,255, 0.8) ;
    
}


.free-layout{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 88px;
    margin-top: 72px;

}

.free-layout img{
    width: 200px;
    height: 200px;
}

.free-layout-right {
    display: flex;
    flex-direction: column;
    margin-left: 120px;
}

.free-layout-right p{
    color: #fff;
    font-size: 40px;
}
.free-layout-right span{
    color: rgba(255, 255, 255, 0.8) ;
    font-size: 18px;
}



